import styled from "styled-components";
import React from "react";

import Link from "@mui/material/Link";

import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";

const StyledComponent = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (min-width: 641px) {
    flex-direction: row;
  }
`;

const StyledEmpty = styled.div`
  flex-basis: 33%;
`;

const StyledTitle = styled.div`
  margin: -50px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media (min-width: 641px) {
    flex-basis: 33%;
    margin: 0;
  }
`;

const StyledDescription = styled.div`
  margin: 5px 5px 0 0;

  @media (min-width: 641px) {
    flex-basis: 33%;
    margin: 10px 10px 0 0;
  }
`;

const StyledDescriptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: end;
  text-wrap: nowrap;
  margin-bottom: 5px;
  gap: 5px;
`;

const Header = () => {
  return (
    <StyledComponent>
      <StyledEmpty />
      <StyledTitle>
        <div>
          <div style={{ fontSize: "xxx-large", lineHeight: "0.7" }}>Web</div>
          <div style={{ fontSize: "xx-large" }}>Developer</div>
          <div style={{ fontSize: "small" }}>OLIVIER MURAT</div>
        </div>
      </StyledTitle>
      <StyledDescription>
        <StyledDescriptionItem>
          2-37-5, Hontenuma
          <br />
          Suginami-ku, Tokyo 167-0031
          <PlaceIcon />
        </StyledDescriptionItem>
        <StyledDescriptionItem>
          +81 80 1030 4708
          <PhoneIcon />
        </StyledDescriptionItem>
        <StyledDescriptionItem>
          oliv.murat@gmail.com
          <MailIcon />
        </StyledDescriptionItem>
        <StyledDescriptionItem>
          31 years old
          <CalendarMonthIcon />
        </StyledDescriptionItem>
        <StyledDescriptionItem>
          <Link
            href="https://www.linkedin.com/in/olivmurat"
            target="_blank"
            rel="noopener"
            color="inherit"
            underline="hover"
          >
            LinkedIn
          </Link>
          <LinkedInIcon />
        </StyledDescriptionItem>
        <StyledDescriptionItem>
          <Link
            href="https://gitlab.com/users/StoutSan/projects"
            target="_blank"
            rel="noopener"
            color="inherit"
            underline="hover"
          >
            GitLab
          </Link>
          <GitHubIcon />
        </StyledDescriptionItem>
      </StyledDescription>
    </StyledComponent>
  );
};

export default Header;
