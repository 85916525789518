import styled from "styled-components";
import React from "react";

const StyledList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
  width: 50%;
  margin-bottom: 10px;
`;

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
`;

const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const Languages = ({ data }) => {
  return (
    <StyledList>
      {data.map(({ label, flag, level }, index) => (
        <StyledItem key={index}>
          <StyledImage src={flag} alt={label} />
          <StyledDescription>
            <span>{label}</span>
            <span>{level}</span>
          </StyledDescription>
        </StyledItem>
      ))}
    </StyledList>
  );
};

export default Languages;
