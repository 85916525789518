import styled from "styled-components";
import React from "react";

const StyledList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 641px) {
    flex-direction: row;
  }
`;

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  @media (min-width: 641px) {
    width: 30%;
  }
`;

const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Hobbies = ({ data }) => {
  return (
    <StyledList>
      {data.map(({ label, icon, description }, index) => (
        <StyledItem key={index}>
          {icon}
          <StyledDescription>
            <span>{label}</span>
            <span>{description}</span>
          </StyledDescription>
        </StyledItem>
      ))}
    </StyledList>
  );
};

export default Hobbies;
