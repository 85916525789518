import "./App.css";
import styled from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Menu from "./components/menu";
import Header from "./components/header";
import Category from "./components/category";
import Skills from "./components/skills";
import Languages from "./components/languages";
import Hobbies from "./components/hobbies";
import Presentation from "./components/presentation";

import PersonIcon from "@mui/icons-material/Person";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import TranslateIcon from "@mui/icons-material/Translate";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ExtensionIcon from "@mui/icons-material/Extension";
import WorkIcon from "@mui/icons-material/Work";
import DevicesIcon from "@mui/icons-material/Devices";
import SchoolIcon from "@mui/icons-material/School";

import Profil from "./assets/profil.png";
import {
  summary,
  skills,
  languages,
  visa,
  hobbies,
  experience,
  projects,
  education,
} from "./data";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const StyledComponent = styled.div`
  background-color: #000000;
  min-height: 100%;
  border: 10px solid #004659;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media (min-width: 641px) {
    border: 20px solid #004659;
  }
`;

const StyledProfil = styled.div`
  position: absolute;
  left: 10px;
  width: 150px;
  height: 150px;
  background-color: #004659;
  margin: -20px 0 0 -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0% 0% 50% 0%;

  @media (min-width: 641px) {
    left: 20px;
    width: 200px;
    height: 200px;
  }
`;

const StyledImage = styled.img`
  width: 100px;
  height: 100px;

  @media (min-width: 641px) {
    width: 150px;
    height: 150px;
  }
`;

const StyledShape1 = styled.div`
  position: absolute;
  left: 140px;

  @media (min-width: 641px) {
    left: 200px;
  }
`;

const StyledShape2 = styled.div`
  position: absolute;
  top: 140px;

  @media (min-width: 641px) {
    top: 200px;
  }
`;

const StyledShapeBack = styled.div`
  z-index: 1;
  background-color: #004659;
  width: 100px;
  height: 100px;

  @media (min-width: 641px) {
    width: 200px;
    height: 200px;
  }
`;

const StyledShapeFront = styled.div`
  z-index: 2;
  background-color: #000000;
  width: 100%;
  height: 100%;
  border-radius: 50% 0% 0% 0%;
`;

const StyledPhone = styled.div`
  z-index: 3;
  display: flex;
  flex-direction: column;

  @media (min-width: 641px) {
    display: none;
  }
`;

const StyledScreen = styled.div`
  z-index: 3;
  display: none;

  @media (min-width: 641px) {
    display: flex;
  }
`;

const StyledHalf = styled.div`
  width: 50%;
`;

const App = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <StyledComponent>
          <StyledProfil>
            <StyledImage src={Profil} alt="Olivier Murat" />
          </StyledProfil>
          <StyledShape1>
            <StyledShapeBack>
              <StyledShapeFront />
            </StyledShapeBack>
          </StyledShape1>
          <StyledShape2>
            <StyledShapeBack>
              <StyledShapeFront />
            </StyledShapeBack>
          </StyledShape2>
          <Header />
          <StyledPhone>
            <Category title="Summary" icon={<PersonIcon />}>
              {summary.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </Category>
            <Category title="Skills" icon={<LightbulbIcon />}>
              <Skills data={skills} />
            </Category>
            <Category title="Experience" icon={<WorkIcon />}>
              <Presentation data={experience} />
            </Category>
            <Category title="Projects" icon={<DevicesIcon />}>
              <Presentation data={projects} />
            </Category>
            <Category title="Education" icon={<SchoolIcon />}>
              <Presentation data={education} />
            </Category>
            <Category title="Languages" icon={<TranslateIcon />}>
              <Languages data={languages} />
            </Category>
            <Category title="Visa" icon={<TravelExploreIcon />}>
              <Presentation data={visa} />
            </Category>
            <Category title="Hobbies" icon={<ExtensionIcon />}>
              <Hobbies data={hobbies} />
            </Category>
          </StyledPhone>
          <StyledScreen>
            <StyledHalf>
              <Category title="Summary" icon={<PersonIcon />}>
                {summary.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </Category>
              <Category title="Skills" icon={<LightbulbIcon />}>
                <Skills data={skills} />
              </Category>
              <Category title="Languages" icon={<TranslateIcon />}>
                <Languages data={languages} />
              </Category>
              <Category title="Visa" icon={<TravelExploreIcon />}>
                <Presentation data={visa} />
              </Category>
              <Category title="Hobbies" icon={<ExtensionIcon />}>
                <Hobbies data={hobbies} />
              </Category>
            </StyledHalf>
            <StyledHalf>
              <Category title="Experience" icon={<WorkIcon />}>
                <Presentation data={experience} />
              </Category>
              <Category title="Projects" icon={<DevicesIcon />}>
                <Presentation data={projects} />
              </Category>
              <Category title="Education" icon={<SchoolIcon />}>
                <Presentation data={education} />
              </Category>
            </StyledHalf>
          </StyledScreen>
        </StyledComponent>
        <Menu />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
