import HeadphonesIcon from "@mui/icons-material/Headphones";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";

import FlagFR from "./assets/fr.png";
import FlagUK from "./assets/uk.png";
import FlagJP from "./assets/jp.png";

export const summary = [
  "Currently looking for a new personal experience and wanting to get out of,my comfort zone, I thought a lot about moving abroad, and Japan seemed obvious to me.",
  "Being an open-minded individual, the idea of discovering a new culture while simultaneously discovering new professional environments gives me great motivation to pursue this opportunity.",
  "I worked on the different technical stages of the life of a web application, from understanding the needs of the clients to the development phase and eventual maintenance and improvement necessary to run an effective application.",
  "I am able to work on projects efciently both in teams and independently, whether being at the ofce or remotely.",
  "Throughout my career, I have been able to work on several projects in different elds including the airline industry, medical training, and even a telecommunications company.",
  "Due to my ability to learn and adapt quickly, I'm always open to learning new skills and industries.",
];

export const skills = [
  { label: "ReactJS", level: 4 },
  { label: "Redux", level: 3 },
  { label: "Angular", level: 4 },
  { label: "Jest", level: 3 },
  { label: "VueJS", level: 2 },
  { label: "React Testing Library", level: 3 },
  { label: "HTML / CSS", level: 4 },
  { label: "Git", level: 3 },
  { label: "JavaScript", level: 3 },
  { label: "npm, yarn, maven", level: 3 },
  { label: "TypeScript", level: 3 },
  { label: "Agile", level: 3 },
  { label: "Java", level: 3 },
  { label: "Spring (Boot)", level: 2 },
  { label: "MySQL, Mongo", level: 2 },
  { label: "Docker", level: 1 },
  { label: "Flutter // TO DO", level: 0 },
  { label: "AWS, Google Cloud // TO DO", level: 0 },
];

export const languages = [
  { label: "French", flag: FlagFR, level: "native" },
  { label: "English", flag: FlagUK, level: "fluent" },
  { label: "Japanese", flag: FlagJP, level: "work in progress" },
];

export const visa = [
  {
    title: "Working Holiday, Japan - Tokyo",
    date: "November 2023 - November 2024",
  },
];

export const hobbies = [
  {
    label: "Music",
    icon: <HeadphonesIcon />,
    description:
      "(Atarashii Gakko, Kaneko Ayano, YOASOBI, RADWIMPS, BABYMETAL...) ",
  },
  {
    label: "Gaming",
    icon: <SportsEsportsIcon />,
    description:
      "(League of Legends, Minecraft, Nintendo, city-building games...) ",
  },
  {
    label: "Anime",
    icon: <PersonalVideoIcon />,
    description:
      "(One Piece, Fullmetal Alchemist, Dragon Ball, Samurai Champloo...)",
  },
];

export const experience = [
  {
    title: "Web Developer - DataSoluce, Toulouse (France)",
    date: "May 2021 - July 2023",
    descriptions: [
      "Development of the DataSoluce web application, a platform that allows professionals working in construction to centralize the data of their projects over the entire life cycle of the building. (Agile, React 17/18, Jest, TypeScript)",
    ],
  },
  {
    title: "Web Developer - Inovans/We+, Toulouse (France)",
    date: "August 2018 - July 2020",
    descriptions: [
      "Post-graduate internship converted to long-term work contract",
    ],
    subData: [
      {
        title: "AIR FRANCE",
        descriptions: [
          "Development of the Izidoor web site, allowing the reservation of an accompaniment service from home to the airport (Agile, Daily Meeting, Angular 8, Kotlin, Strapi)",
          "Development of the web interface of the Easy2Trip site, customizable travel booking site (Agile, Angular 6)",
          "Development of the web interface of the FlexFly site, site for buying / selling plane tickets (Agile, Angular 6)",
          "Development of Air France's Flying Blue site, company loyalty site (Agile, Angular 6, NodeJS)",
        ],
      },
      {
        title: "WE+",
        descriptions: [
          "Development of the presentation site of the We+ Toulouse's Digital Factory, and it UI Kit (Agile, Angular 8, TailwindCSS, Kotlin, Strapi)",
        ],
      },
      {
        title: "DYNAFIN",
        descriptions: [
          "Development of Dynan's presentation and recruitment site (Agile, Angular 7, Java, Strapi)",
        ],
      },
      {
        title: "WIN BY INWI",
        descriptions: [
          "Improvement of the backofce for the site of a telecommunications company (Drupal, PHP)",
        ],
      },
      {
        title: "JOON",
        descriptions: [
          "Development of the P2C-JOON site, allowing the taking of leave (Agile, Angular 6, Java 8)",
        ],
      },
    ],
  },
  {
    title: "Developer - Form'Utip, Toulouse (France)",
    date: "October 2016 - September 2017",
    descriptions: [
      "Alternance - Development of a training interface for pharmacists (Java, Swing)",
    ],
  },
  {
    title: "Web Developer - Artefact, Brive la Gaillarde (France)",
    date: "June - August 2015",
    descriptions: [
      "Internship - Development of a CMS for the creation of showcase sites.",
    ],
  },
];

export const projects = [
  {
    title: "Web CV (WIP)",
    url: "oliviermurat.fr",
    descriptions: [
      "CV site presenting my professional and personal achievements (work in progress to make it more interactive) (React 18)",
    ],
  },
  {
    title: "Nihongo No Memo",
    url: "nihongonomemo.fr",
    descriptions: [
      "Web application helping me learn Japanese (Addition of vocabularies, exercises...) (React 18, Strapi)",
    ],
  },
  {
    title: "Shot By Bee",
    url: "shotbybee.fr",
    descriptions: [
      "Portfolio site for a photographer (authentication to manage content) (React 18, Strapi)",
    ],
  },
];

export const education = [
  {
    title: "Bachelor's degree, Bossuet, Brive-la-Gaillarde (France)",
    date: "September 2011",
    descriptions: ["S/SVT section"],
  },
  {
    title: "IT and Information Systems Expert, Ynov, Toulouse (France)",
    date: "2013 - 2018",
    descriptions: [
      "HTML, CSS, JS, Java, C, C++, C#, Java Android, Swift, Python, SQL, SEO, Security, Cisco, BI, IOT, Agile....",
      "Y-Days (days dedicated to a group project developed throughout the year)",
      "Student association (main organizer of internal social/gaming events)",
    ],
  },
];
