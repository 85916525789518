import styled from "styled-components";
import React from "react";
import Rating from "@mui/material/Rating";

const StyledList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 50%;
  margin-bottom: 10px;
`;

const Skills = ({ data }) => {
  return (
    <StyledList>
      {data.map(({ label, level }, index) => (
        <StyledItem key={index}>
          {label}
          <Rating value={level} readOnly />
        </StyledItem>
      ))}
    </StyledList>
  );
};

export default Skills;
