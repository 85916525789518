import styled from "styled-components";
import { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PersonIcon from "@mui/icons-material/Person";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import TranslateIcon from "@mui/icons-material/Translate";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ExtensionIcon from "@mui/icons-material/Extension";
import WorkIcon from "@mui/icons-material/Work";
import DevicesIcon from "@mui/icons-material/Devices";
import SchoolIcon from "@mui/icons-material/School";

import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

const StyledComponent = styled.div`
  display: block;

  @media (min-width: 641px) {
    display: none;
  }
`;

const StyledAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const Menu = () => {
  const location = useLocation();
  const lastHash = useRef("");
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        lastHash.current = "";
      }, 100);
    }
  }, [location]);

  const actions = [
    { icon: <ExtensionIcon />, name: "Hobbies" },
    { icon: <TravelExploreIcon />, name: "Visa" },
    { icon: <TranslateIcon />, name: "Languages" },
    { icon: <SchoolIcon />, name: "Education" },
    { icon: <DevicesIcon />, name: "Projects" },
    { icon: <WorkIcon />, name: "Experience" },
    { icon: <LightbulbIcon />, name: "Skills" },
    { icon: <PersonIcon />, name: "Summary" },
  ];

  return (
    <StyledComponent>
      <Backdrop open={open} sx={{ zIndex: 999 }} />
      <SpeedDial
        ariaLabel="Menu"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        {actions.map((action) => (
          <SpeedDialAction
            sx={{ color: "white" }}
            key={action.name}
            icon={<StyledAction>{action.icon}</StyledAction>}
            tooltipTitle={<StyledAction>{action.name}</StyledAction>}
            tooltipOpen
            onClick={() => navigate(`#${action.name.toLowerCase()}`)}
          />
        ))}
      </SpeedDial>
    </StyledComponent>
  );
};

export default Menu;
