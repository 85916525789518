import styled from "styled-components";
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const StyledHeader = styled.div`
  width: 100%;
  margin: 10px 0;
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Children = styled.div`
  margin: 0 5px;
`;

const Category = ({ title, icon, children }) => {
  return (
    <Paper
      id={title.toLowerCase()}
      elevation={0}
      sx={{ margin: "15px", padding: "1px 15px" }}
    >
      <StyledHeader>
        <Divider textAlign={"left"}>
          <StyledTitle>
            {icon}
            <Typography variant="h5">{title}</Typography>
          </StyledTitle>
        </Divider>
      </StyledHeader>
      <Children>{children}</Children>
    </Paper>
  );
};

export default Category;
