import styled from "styled-components";
import React from "react";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import LinkIcon from "@mui/icons-material/Link";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const StyledComponent = styled.div`
  z-index: 1;
`;

const StyledTitle = styled.div`
  display: flex;
  font-size: large;
`;

const StyledDate = styled.div`
  display: flex;
  font-size: small;
  margin-left: 10px;
`;

const StyledDescription = styled.div`
  display: flex;
  font-size: medium;
  margin-top: 5px;
  margin-left: 10px;
`;

const StyledSubTitle = styled.div`
  display: flex;
  font-size: medium;
`;

const StyledSubDescription = styled.div`
  display: flex;
  font-size: medium;
  margin-top: 5px;
  margin-left: 10px;
`;

const Presentation = ({ data }) => {
  return (
    <StyledComponent>
      {data.map((item, index) => (
        <Paper
          key={index}
          elevation={1}
          sx={{ marginBottom: "15px", padding: "10px" }}
        >
          <StyledTitle>
            <ArrowForwardIosIcon />
            {item.title}
          </StyledTitle>
          {!!item.date && (
            <StyledDate>
              <HourglassBottomIcon fontSize="small" />
              {item.date}
            </StyledDate>
          )}
          {!!item.url && (
            <StyledDate>
              <LinkIcon fontSize="small" />
              <Link href="#" color="inherit" underline="hover">
                {item.url}
              </Link>
            </StyledDate>
          )}
          {item.descriptions?.map((description, index) => (
            <StyledDescription key={index}>
              <ArrowRightIcon />
              {description}
            </StyledDescription>
          ))}
          {item.subData?.map((subItem, index) => (
            <Paper
              key={index}
              elevation={2}
              sx={{ margin: "10px 0", padding: "10px" }}
            >
              <StyledSubTitle>
                <KeyboardArrowRightIcon />
                {subItem.title}
              </StyledSubTitle>
              {subItem.descriptions.map((description, index) => (
                <StyledSubDescription key={index}>
                  <ArrowRightIcon />
                  {description}
                </StyledSubDescription>
              ))}
            </Paper>
          ))}
        </Paper>
      ))}
    </StyledComponent>
  );
};

export default Presentation;
